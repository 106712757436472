import { isPlatformBrowser } from '@angular/common';
import {
    Directive,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    PLATFORM_ID
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/** @deprecated - this is moved to libs */
@Directive({
    selector: '[ins_debounceClick]'
})

export class DisabledDirective implements OnInit, OnDestroy {
    @Input() debounceTime = 500;
    @Output() readonly debounceClick = new EventEmitter();

    private readonly clicks$ = new Subject<MouseEvent>();
    private subscription: Subscription = new Subscription();
    private isBrowser = false;

    constructor(
        @Inject(PLATFORM_ID) platformId: Object,
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        this.listenToClicks();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    @HostListener('click', ['$event']) clickEvent(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.clicks$.next(event);
    }

    private listenToClicks(): void {
        const clicks$ = this.clicks$
            .pipe(
                debounceTime(this.debounceTime)
            )
            .subscribe((event: MouseEvent) => {
                this.debounceClick.emit(event);
            });
        this.subscription.add(clicks$);
    }
}
