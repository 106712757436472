import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HideOnBreakpointDirective } from '@app/shared/directives/hide-on-breakpoint.directive';
import { AnimateInViewDirective } from './animate-in-view.directive';
import { AuthorizeBeforeRedirectModule } from './authorize-before-redirect.module';
import { ClickOutsideDirective } from './click-outside.directive';
import { CodeBlockDirective } from './codeblock.directive';
import { DecimallizeDirective } from './decimallize.directive';
import { DisabledDirective } from './disabled.directive';
import { DropdownModule } from './dropdown/dropdown.module';
import { ReadMoreModule } from './read-more/read-more.module';

/** @deprecated - this is moved to libs */
@NgModule({
    imports: [
        CommonModule,
        AuthorizeBeforeRedirectModule,
        ReadMoreModule,
        DropdownModule,
    ],
    declarations: [
        AnimateInViewDirective,
        ClickOutsideDirective,
        CodeBlockDirective,
        DecimallizeDirective,
        DisabledDirective,
        HideOnBreakpointDirective,
    ],
    exports: [
        AnimateInViewDirective,
        ClickOutsideDirective,
        CodeBlockDirective,
        DecimallizeDirective,
        DisabledDirective,
        HideOnBreakpointDirective,
        ReadMoreModule,
        DropdownModule,
    ],
})
export class DirectivesModule {
}
