import { isPlatformServer } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '@inshared/shared/util';
/** @deprecated - this is moved to libs */
@Directive({
    selector: '[ins-animate-in-view]'
})

export class AnimateInViewDirective implements AfterViewInit {

    constructor(
        @Inject(WINDOW) private window: Window,
        @Inject(PLATFORM_ID) private platformId: object,
        private elem: ElementRef
    ) { }

    @HostListener('window:scroll', ['$event']) handleScroll(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        const element = this.elem.nativeElement;

        if (typeof element.getBoundingClientRect !== 'function') {
            return;
        }

        if (element.getBoundingClientRect().top < this.window.innerHeight) {
            element.classList.add('animate');
        }
    }

    ngAfterViewInit(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        this.handleScroll();
    }
}
