import { Directive, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@inshared/shared/util';
import { StorageService } from '@app/core/storage/universal.inject';
import { AppRoutesEnum } from '@app/routes';
import { WucButtonComponent, WucLinkComponent } from '@inshared/website-ui-components';
import { AuthenticationDataService } from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';

const TARGET_URL = 'targetUrl';

/** @deprecated - this is moved to libs */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ins-authorize-before-redirect]',
})
export class AuthorizeBeforeRedirectDirective implements OnInit, OnDestroy {
    @Input() url?: string;

    private subscription: Subscription = new Subscription();
    readonly authenticated$: Observable<boolean> = this.authenticationDataService.isLoggedIn$();

    @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
        if (!this.linkComponent && !this.buttonComponent) {
            event.preventDefault();
            this.startNavigation(new URL(this.elem.nativeElement.href).pathname);
        }
    }

    // eslint-disable-next-line no-useless-constructor
    constructor(
        private elem: ElementRef,
        private authenticationDataService: AuthenticationDataService,
        private router: Router,
        @Inject(WINDOW) private window: Window,
        @Inject(StorageService) private storage: Storage,
        @Optional() private linkComponent: WucLinkComponent,
        @Optional() private buttonComponent: WucButtonComponent,
    ) {
    }

    public ngOnInit(): void {
        if (this.linkComponent) {
            const url = this.linkComponent.url;
            this.linkComponent.url = undefined;
            this.subscription.add(this.linkComponent.clicked.subscribe((): void =>
                this.startNavigation(url)
            ));
        }
        if (this.buttonComponent) {
            this.buttonComponent.onClick = (): void => this.startNavigation(this.url);
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private startNavigation(url: string): void {
        this.subscription.add(this.authenticated$.subscribe((isAuthenticated: boolean): void => {
            if (isAuthenticated) {
                this.window.location.href = url;
            } else {
                this.storage.setItem(TARGET_URL, JSON.stringify(url));
                this.router.navigate([AppRoutesEnum.Login]);
            }
        }));
    }
}
