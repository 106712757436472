import { Directive, HostBinding, Input } from '@angular/core';

/** @deprecated - this is moved to libs */
@Directive({
    selector: '[insHideOnBreakpoint]'
})
export class HideOnBreakpointDirective {

    @Input() andBelow: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | null = null;
    @Input() andAbove: 'sm' | 'md' | 'lg' | 'xl' | null = null;

    @HostBinding('class')
    public get class(): string {
        return `hide-${this.andBelow ? this.andBelow : this.andAbove}-and-${this.andBelow ? 'below' : 'above'}`;
    }

}
