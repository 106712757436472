import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

/** @deprecated - this is moved to libs */
@Directive({
    selector: '[ins-clickOutside]'
})
export class ClickOutsideDirective {

    @Output() readonly clickedOutside = new EventEmitter<MouseEvent>();

    constructor(private elementRef: ElementRef) {
    }

    @HostListener('document:click', ['$event', '$event.target'])
    onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!targetElement) {
            return;
        }

        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.clickedOutside.emit(event);
        }
    }
}
