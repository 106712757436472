import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthorizeBeforeRedirectDirective } from './authorize-before-redirect.directive';

/** @deprecated - this is moved to libs */
@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        AuthorizeBeforeRedirectDirective,
    ],
    exports: [
        AuthorizeBeforeRedirectDirective,
    ],
    providers: [],
})
export class AuthorizeBeforeRedirectModule {
}
