import { Input, OnInit, ChangeDetectorRef,
    ElementRef, ContentChild, Directive, Inject, forwardRef, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AutocloseService } from '@app/shared/dropdown/autoclose.service';
import { DropdownOptions } from './dropdown-options.interface';

@Directive({
    selector: '[insDropdownToggle]',
})
export class DropdownToggleDirective {
    public toggleElement;

    constructor(
        // tslint:disable-next-line
        @Inject(forwardRef(() => DropdownDirective)) public dropdown,
        private _elementRef: ElementRef<HTMLElement>
    ) {
        this.toggleElement = _elementRef.nativeElement;
    }

    public getNativeElement(): HTMLElement {
        return this._elementRef.nativeElement;
    }
}

@Directive({
    selector: '[insDropdownMenu]',
    host: {
        '[class.dropdown-menu]': 'true',
        '[class.show]': 'dropdown.isOpen()',
        '[class.dropdown-menu--left]': 'dropdown.classes?.left',
        '[class.dropdown-menu--center]': 'dropdown.classes?.center',
        '[class.dropdown-menu--right]': 'dropdown.classes?.right',
        '[class.dropdown-menu--small]': 'dropdown.classes?.small',
        '[class.dropdown-menu--medium]': 'dropdown.classes?.medium',
        '[class.dropdown-menu--large]': 'dropdown.classes?.large',
        '[class.dropup-menu--right]': 'dropdown.classes?.topright',
        '[class.dropup-menu]': 'dropdown.classes?.top',
    }
})
export class DropdownMenuDirective {
    constructor
        // tslint:disable-next-line
        (@Inject(forwardRef(() => DropdownDirective)) public dropdown
    ) {}
}


@Directive({
    selector: '[insDropdown]',
    exportAs: 'dropdown',
    host: {
        'class': 'dropdown-container'
    }
})
export class DropdownDirective implements OnInit, OnDestroy {

    @ContentChild(DropdownMenuDirective, { static: true }) private _menu: DropdownMenuDirective;
    @ContentChild(DropdownMenuDirective, { static: true, read: ElementRef }) private _menuElement: ElementRef;
    @ContentChild(DropdownToggleDirective, { static: true }) private _toggle: DropdownToggleDirective;

    private _open: boolean = false;
    private _defaultOptions = {
        align: 'left',
        size: 'small',
        valign: 'bottom',
    };

    @Input() options: DropdownOptions;

    public closed$: Subject<void> = new Subject<void>();
    public classes: { [key: string]: boolean };

    constructor(
        private cd: ChangeDetectorRef,
        private autoclose: AutocloseService
    ) { }

    ngOnInit(): void {
        this.setClasses({ ...this._defaultOptions, ...this.options });
    }

    public isOpen(): boolean {
        return this._open;
    }

    public open(): void {
        if (!this.isOpen()) {
            this.cd.markForCheck();
            this._open = true;

            this.setCloseHandlers();
        }
    }

    public close(): void {
        if (this.isOpen()) {
            this.cd.markForCheck();
            this._open = false;

            this.closed$.next();
        }
    }

    public toggle(): void {
        this.isOpen() ? this.close() : this.open();
    }

    ngOnDestroy(): void {
        this.closed$.next();
    }

    private setClasses(options: DropdownOptions): void {
        this.classes = {
            'top': options.valign === 'top',
            'left': options.align === 'left',
            'center': options.align === 'center',
            'right': options.align === 'right',
            'topright': options.align === 'top-right',
            'small': options.size === 'small',
            'medium': options.size === 'medium',
            'large': options.size === 'large',
        };
    }

    private setCloseHandlers(): void {
        this.autoclose.setup(
            () => this.close(),
            this.closed$,
            this._menu ? [this._menuElement.nativeElement] : [],
            this._toggle ? [this._toggle.getNativeElement()] : []
        );
    }
}
