import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { delay, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { DeviceDetectorService } from '@app/core/device-detector/device-detector.service';
import { EventListenerService } from '@app/core/services/event-listener.service';

@Injectable({
    providedIn: 'root'
})
export class AutocloseService implements OnDestroy {
    private subscriptions = new Subscription();
    public iOS: boolean = this.deviceDetectorService.isIOS();

    constructor(
        private deviceDetectorService: DeviceDetectorService,
        private eventListenerService: EventListenerService,
    ) {
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public setup(close: () => void, closed$: Observable<void>, insideElements: HTMLElement[], ignoreElements: HTMLElement[]): void {

        const mouseDowns$ = this.eventListenerService.documentEvent<MouseEvent>('mousedown').pipe(
            map(event => this.shouldCloseOnClick(event, insideElements, ignoreElements)),
            takeUntil(closed$)
        );

        const closeableClicks$ = this.eventListenerService.documentEvent<MouseEvent>('mouseup').pipe(
            withLatestFrom(mouseDowns$),
            filter(([_, shouldClose]) => shouldClose),
            delay(this.iOS ? 16 : 0),
            takeUntil(closed$)
        );

        const click = closeableClicks$.subscribe(() => {
            close();
        });
        this.subscriptions.add(click);
    }

    public shouldCloseOnClick(event: MouseEvent | TouchEvent, insideElements: HTMLElement[], ignoreElements: HTMLElement[]): boolean {
        const element = event.target as HTMLElement;

        if (this.isContainedIn(element, ignoreElements)) {
            return false;
        }

        if (this.isContainedIn(element, insideElements)) {
            return this.isClickableElement(element);
        }

        return !this.isContainedIn(element, insideElements);
    }

    public isContainedIn(element: HTMLElement, array?: HTMLElement[]): boolean {
        return array ? array.some(item => item.contains(element)) : false;
    }

    /**
     * When an element is a button or a link, it is a clickable element.
     * You can add elements to the list to make sure that the dropdown closes on click
     */
    private isClickableElement(element: HTMLElement): boolean {
        const result =
            // anchor elements ( <a> )
            element instanceof HTMLAnchorElement ||
            element.parentElement instanceof HTMLAnchorElement ||
            // button elements ( <button> )
            element instanceof HTMLButtonElement ||
            element.parentElement instanceof HTMLButtonElement;
        return result;
    }
}
